/** @jsx jsx */
import { css, jsx } from "@emotion/react"
import React, {
  Fragment,
  useEffect,
  useState,
  useContext,
  useRef,
  useInsertionEffect,
} from "react"
import { Dialog, Transition } from "@headlessui/react"
// import LayoutDashborad from "../components/Layout/Dashboard"
// import { parse } from "query-string"
import TimeAgo from "javascript-time-ago"

import en from "javascript-time-ago/locale/en.json"
import ru from "javascript-time-ago/locale/ru.json"

import {
  // getMyListing,
  // getMyMessageReceived,
  // getMyMessageSent,
  getMyMessageThread,
  PostMyMessageThread,
  getSingleAsset,
  actionRequestAssetRequest,
} from "../helpers/DrupalHelper"
import StateContext from "../context/state/StateContext"
import CloseIcon from "../assets/icons/close-alt.svg"
// import Modal from "../components/Modal/Modal"
// import EditFormTemplate from "../components/Forms/EditFormTemplate"
import NotificationContext from "../context/Notification/NotificationContext"
import FloatingTextarea from "../components/Elements/Form/FloatingTextarea"
import ShopIcon from "../assets/icons/shop.svg"
import { Table, Tr, Td } from "../components/Table/TableElements"
import {
  // CheckCircleIcon,
  // ChevronRightIcon,
  // MailIcon,
  // InboxInIcon,
  ChatAlt2Icon,
} from "@heroicons/react/outline"
import CircleAvatar from "../components/Elements/CircleAvatar"
// import { Tab } from "@headlessui/react"
import AuthenticationContext from "../context/authentication/AuthenticationContext"
import {
  //  classNames,
  formatDate,
} from "../utils"
// import { navigate } from "gatsby"
import Button from "../components/Elements/Button"
import { useMediaQuery } from "react-responsive"
import ReactTimeAgo from "react-time-ago"
import {
  validateEmail,
  validateUrl,
  // validatePhone,
  stringIncludesCanadianTelephone,
} from "../utils/messageValidation"
import ImageCardSmall from "../components/Elements/ImageCardSmall"
import Logo from "../assets/logos/o2o-logo-r.png"
import Modal from "../components/Modal/Modal"
import { Link } from "gatsby"
const MessageMetaInfo = props => {
  const { title, body } = props
  return (
    <p className="flex flex-col">
      <span className="text-med-14">{title}</span>
      <span className="text-reg-14 !leading-[1.4] mt-1 mb-3">{body}</span>
    </p>
  )
}
function MessagesList(props) {
  const { id, token, open, onClose } = props
  const [data, setData] = useState(null)
  // const [assetData, setAssetData] = useState(null)
  const [body, setBody] = useState("")
  const [brandView, setBrandView] = useState(false)
  const { toggleNotification } = useContext(NotificationContext)
  const { authentication, userType } = useContext(AuthenticationContext)
  const { currentUserData } = authentication
  const {
    //  companies,
    brands,
  } = currentUserData
  const fieldRef = useRef(null)
  const isMobile = useMediaQuery({
    query: "(max-width: 768px)",
  })
  TimeAgo.addDefaultLocale(en)
  TimeAgo.addLocale(ru)
  // useEffect(async () => {
  //   TimeAgo.addDefaultLocale(en)
  //   TimeAgo.addLocale(ru)
  // }, [])
  useEffect(async () => {
    if (id) {
      let data = await getMyMessageThread(token, id)
      // console.log(data)
      // if (!data) {
      //   navigate("/dashboard/messages/")
      // } else {
      setData(data)
      // }
      // console.log(id)
    }
  }, [id])

  useEffect(async () => {
    // if (id && data !== null && data.webform.includes("Asset")) {
    //   let fetchAsset = await getSingleAsset(token, data.asset)
    //   fetchAsset = await fetchAsset.json()
    //   setAssetData(fetchAsset)
    // }
  }, [data])

  useEffect(() => {
    if (data && data.brand && data.brand.name) {
      if (
        brands.filter(
          f => f.title.toLowerCase() == data.brand.name.toLowerCase()
        ).length > 0
      )
        setBrandView(true)
    }
  }, [data, brands])

  const [assetRequestStatus, setAssetRequestStatus] = useState("pending")
  useEffect(() => {
    if (data?.id && "webform" in data && data?.webform === "Request Asset") {
      const { approval_status } = data
      if (approval_status !== null) setAssetRequestStatus(approval_status)
    }
  }, [data])

  useEffect(() => {
    return () => {
      setAssetRequestStatus("pending")
    }
  }, [])
  const requestAssetAction = async (approved = false) => {
    const action = approved ? "approved" : "denied"
    const req = await actionRequestAssetRequest(token, id, action)

    if (req.status === 200) {
      console.log(req, "req")

      setAssetRequestStatus(action)
      toggleNotification({
        content: `Success`,
      })
    } else {
      toggleNotification({
        content: `Failure`,
        error: true,
      })
    }
  }
  // console.log(currentUserData, "currentUserData")
  const onSend = async () => {
    // console.log(body)
    let myString = body
    let strArray = myString.split(" ")
    let found = strArray.some(
      str =>
        /*validatePhone(str) ||*/ stringIncludesCanadianTelephone(str) ||
        validateEmail(str) ||
        validateUrl(str)
    )
    // console.log(found)
    if (found) {
      toggleNotification({
        content: `Oops, please don't share email addresses, weblinks, or phone numbers.`,
        error: true,
      })
    } else {
      let res = await PostMyMessageThread(token, id, { body: body })
      if (res.status === 200) {
        toggleNotification({ content: `Message Sent` })
        let data = await getMyMessageThread(token, id)
        // console.log(data)
        // if (!data) {
        //   navigate("/dashboard/messages/")
        // } else {

        setBody("")
        setData(data)
        fieldRef.current.scrollIntoView({
          behavior: "smooth",
        })
      } else {
        toggleNotification({ content: `Fail to Sent Message`, error: true })
      }
    }
  }
  console.log(data)

  useEffect(() => {
    console.log(assetRequestStatus)
  }, [assetRequestStatus])

  function calcTime(time) {
    var today = new Date(formatDate(time))
    console.log(today, time)
    today.setHours(today.getHours() - 1 + -new Date().getTimezoneOffset() / 60)
    return today
  }

  return (
    <Modal
      md
      isOpen={open}
      title={data?.title || data?.webform || "Message Thread"}
      closeModal={onClose}
    >
      <div style={{ scrollbarWidth: "none" }}>
        <div className="overflow-y-auto h-full px-[15px] md:h-auto md:px-0 md:overflow-y-visible space-y-6">
          <div>
            <div className="flex flex-row  h-full mb-6">
              <div className="flex flex-row flex-auto">
                <div className="w-full flex flex-col">
                  <div className="pt-5 md:pt-0 flex items-center border-b pb-5 w-full">
                    <div className="flex items-center w-full">
                      <div className="flex items-center">
                        {data?.webform === "Notification" &&
                          <CircleAvatar
                            sm
                            border
                            image={Logo}
                            padding="6px"
                            alt={"O2O"}
                          />
                        }
                        {data?.webform === "General Message" &&
                          <CircleAvatar
                            sm
                            border
                            profile
                            image={data?.from?.avatar || null}
                            alt={data?.from?.name || null}
                          />
                        }
                        {data?.webform === "Request Asset" &&
                          <CircleAvatar
                            sm
                            border
                            profile
                            image={data?.requester?.avatar || null}
                            alt={data?.requester?.avatar || null}
                          />
                        }
                        {data?.webform === "Request Visit" &&
                          <CircleAvatar
                            sm
                            border
                            profile
                            image={data?.requester?.avatar || null}
                            alt={data?.requester?.avatar || null}
                          />
                        }

                        {data?.webform !== "Notification" && data?.webform !== "General Message" && data?.webform !== "Request Asset" && data?.webform !== "Request Visit" &&
                          <CircleAvatar
                            sm
                            border
                            profile
                            image={
                              data?.webform !== "General Message"
                                ? data?.brand?.logo
                                : Number(currentUserData.i) === data?.to?.id
                                  ? data?.from?.avatar
                                  : data?.to?.avatar
                            }
                            alt={data?.to?.name || data?.from?.name || null}
                          />
                        }
                        <div className="flex flex-col items-start ml-[13px]">
                          <span
                            className="text-meta-sm !text-left !leading-[1] flex flex-col"
                            css={css`
                              &&& {
                                font-size: 14px;
                                letter-spacing: 0.02em;
                              }
                            `}
                          >


                            {data?.webform === "General Message" &&
                              <>
                                <>{data?.from?.name}</>
                                <span className="text-reg-14">{data.store?.name || data.brand?.name}</span>
                              </>
                            }
                            {data?.webform === "Request Asset" &&
                              <>
                                <>{data?.requester?.name}</>
                                <span className="text-reg-14">{data?.requester?.companies.join(', ')}</span>
                              </>
                            }
                            {data?.webform === "Request Visit" &&
                              <>
                                <>{data?.requester?.name}</>
                                <span className="text-reg-14">{data?.store?.name}</span>
                              </>
                            }
                            {data?.webform === "Contact Us" &&
                              <>
                                <>{data?.from?.name}</>
                                <span className="text-reg-14">{data?.store?.name}</span>
                              </>
                            }
                            {/* {brandView === true && (
                              <>
                                {data?.store?.name}
                                {data?.store?.retailer?.name &&
                                  `, ${data?.store?.retailer?.name}`}
                              </>
                            )}
                            {Number(currentUserData.i) === data?.to?.id
                              ? data?.from?.name
                              : data?.to?.name} */}
                          </span>
                        </div>
                      </div>
                      <div className="ml-auto">
                        <span className="flex items-center text-reg-12">
                          <span className="w-[20px]">
                            {data &&
                              "webform" in data &&
                              data?.webform.includes("visit") ? (
                              <ShopIcon className="w-3 w-3" />
                            ) : (
                              <ChatAlt2Icon
                                className="w-4 w-4"
                                css={css`
                                  stroke-width: 1px;
                                  stroke: #222;
                                `}
                              />
                            )}
                          </span>
                          {data?.webform}
                        </span>
                      </div>
                    </div>
                  </div>
                  {brandView === true && data?.store?.location?.address && (
                    <div className="pt-5 flex items-center border-b pb-5 w-full">
                      <span className="text-reg-12">
                        {data.store.location.address}
                      </span>
                    </div>
                  )}

                  <div className="flex-auto overflow-y-auto  py-6 space-y-4">
                    {data?.body &&
                      !data?.webform?.includes("asset") &&
                      !data?.webform?.includes("Visit") &&
                      !data?.webform?.includes("General") && (
                        <div className="flex flex-row space-x-2">

                          {/* <CircleAvatar
                            sm
                            border
                            profile
                            image={Logo}
                            padding="6px"
                          /> */}

                          <div className="flex flex-col">
                            <div className="bg-[#F7F7F7] rounded-[8px] p-5 text-reg-16 !leading-[1.5]">
                              {data.body}
                            </div>
                            <div className="text-xs text-gray-600 mt-1">
                              <ReactTimeAgo
                                date={calcTime(data.created)}
                                locale="en-US"
                              />
                            </div>
                          </div>
                        </div>
                      )}
                    {data &&
                      "webform" in data &&
                      data?.webform.includes("Asset") && (
                        <div className="flex flex-col w-full">
                          <div className="bg-[#F7F7F7] rounded-[8px] p-3 text-reg-16 !leading-[1.5]">
                            <div className="bg-white border border-[#EBEBEB] rounded-[8px]">
                              <div className="flex space-x-2 p-4 items-center">
                                <ImageCardSmall
                                  size={60}
                                  image={data.asset.cover[0]}
                                />
                                <div className="flex flex-col space-y-1">
                                  {/* <p className="text-med-10 !leading-[12px]">{data.brand.name}</p> */}
                                  <p className="text-reg-14 text-dark !leading-[15px]">
                                    {data.asset.title}
                                  </p>
                                  <p className="text-reg-14 mt-[2px] !leading-[15px]">
                                    {data.asset.sku}
                                  </p>
                                </div>
                              </div>
                              <div className="px-4">
                                <Table
                                  sm
                                  columns={["Address", "Qty"]}
                                  flush
                                  flushHead
                                >
                                  {"delivery_locations" in data &&
                                    data.delivery_locations.map((item, k) => {
                                      return (
                                        <Tr key={k}>
                                          <Td>
                                            <span className="whitespace-pre-wrap text-reg-14 !leading-[1.5]">
                                              {item.location}
                                            </span>
                                          </Td>
                                          <Td>
                                            <span className="text-reg-14 !leading-[1.5]">
                                              {data?.quantities[k]}
                                            </span>
                                          </Td>
                                        </Tr>
                                      )
                                    })}
                                </Table>
                              </div>
                            </div>
                            <div className="px-4 py-4">
                              <MessageMetaInfo
                                title={"Requester"}
                                body={
                                  <>
                                    {data.requester.name && (
                                      <>
                                        {data.requester.name} <br />
                                      </>
                                    )}
                                    {data.requester.job_title && (
                                      <>
                                        {data.requester.job_title} <br />
                                      </>
                                    )}
                                    {data.requester.companies.join(", ")}
                                  </>
                                }
                              />
                              {data?.products && (
                                <MessageMetaInfo
                                  title={"Products carried"}
                                  body={data.products
                                    .map(i => i.title)
                                    .join(", ")}
                                />
                              )}
                              {data?.message && (
                                <MessageMetaInfo
                                  title={"Message"}
                                  body={data.message}
                                />
                              )}

                              {/* <div className="flex align-center space-x-2 mt-4">
                                  <Button red widthAuto onClick={() => requestAssetAction(true)}>Approve</Button>
                                  <Button black outline widthAuto onClick={() => requestAssetAction(false)}>Deny</Button>
                                </div> */}

                              {assetRequestStatus === "pending" &&
                                (userType.brandEditor === true ||
                                  userType.brandManager === true) && (
                                  <div className="flex align-center space-x-2 mt-4">
                                    <Button
                                      red
                                      widthAuto
                                      onClick={() => requestAssetAction(true)}
                                    >
                                      Approve
                                    </Button>
                                    <Button
                                      black
                                      outline
                                      widthAuto
                                      onClick={() => requestAssetAction(false)}
                                    >
                                      Deny
                                    </Button>
                                  </div>
                                )}
                              {assetRequestStatus !== "pending" && (
                                <div>
                                  {userType.brandEditor === true ||
                                    (userType.brandManager === true && (
                                      <>
                                        {assetRequestStatus === "approved"
                                          ? "You've approved this request."
                                          : "You've denied this request."}
                                      </>
                                    ))}
                                  {userType.retailerEditor === true ||
                                    (userType.retailerManager === true && (
                                      <>
                                        {assetRequestStatus === "approved"
                                          ? "This request has been approved."
                                          : "This request has been denied."}
                                      </>
                                    ))}
                                </div>
                              )}
                            </div>
                          </div>
                          <div className="text-xs text-gray-600 mt-1">
                            <ReactTimeAgo
                              date={calcTime(data.created)}
                              locale="en-US"
                            />
                          </div>
                        </div>
                      )}

                    {data &&
                      "webform" in data &&
                      data?.webform.includes("Visit") && (
                        <div className="flex flex-col w-full">
                          <div className="bg-[#F7F7F7] rounded-[8px] p-3 text-reg-16 !leading-[1.5]">
                            <div className="px-4 py-4">
                              {/* <MessageMetaInfo
                                    title={"Requester"}
                                    body={
                                      <>

                                      </>
                                    }
                                  /> */}
                              {data?.body && data?.body !== "" && (
                                <MessageMetaInfo
                                  title={"Message"}
                                  body={data.body}
                                />
                              )}
                              {data?.purpose && data?.purpose !== "" && (
                                <MessageMetaInfo
                                  title={"Purpose"}
                                  body={data.purpose}
                                />
                              )}
                              {data?.days && data?.days !== "" && (
                                <MessageMetaInfo
                                  title={"Days"}
                                  body={data.days}
                                />
                              )}
                              {data?.time_of_day &&
                                data?.time_of_day !== "" && (
                                  <MessageMetaInfo
                                    title={"Time of Day"}
                                    body={data.time_of_day}
                                  />
                                )}
                              {data?.store && (
                                <MessageMetaInfo
                                  title={"Location"}
                                  body={
                                    <>
                                      {data.store.name} <br />
                                      {data.store.location.address} <br />
                                    </>
                                  }
                                />
                              )}
                            </div>
                          </div>
                          <div className="text-xs text-gray-600 mt-1">
                            <ReactTimeAgo
                              date={calcTime(data.created)}
                              locale="en-US"
                            />
                          </div>
                        </div>
                      )}
                    {data &&
                      "webform" in data &&
                      data?.webform.includes("General Message") && (
                        <div className="flex flex-col w-full">
                          <div className="bg-[#F7F7F7] rounded-[8px] p-3 text-reg-16 !leading-[1.5]">
                            <div className="px-4 py-4">
                              {/* <MessageMetaInfo
                                    title={"Requester"}
                                    body={
                                      <>

                                      </>
                                    }
                                  /> */}
                              {data?.body && data?.body !== "" && (
                                <MessageMetaInfo
                                  title={"Message"}
                                  body={data.body}
                                />
                              )}

                              {data?.assets && data?.assets.length && (
                                <MessageMetaInfo
                                  title={"Assets"}
                                  body={data?.assets?.map(item => (
                                    <Link to={`/asset/${item.id}`}>
                                      <div className="bg-white border border-[#EBEBEB] rounded-[8px]">
                                        <div className="flex space-x-2 p-4 items-center">
                                          <ImageCardSmall
                                            size={60}
                                            image={
                                              Array.isArray(item?.cover)
                                                ? item?.cover[0]
                                                : item.cover
                                            }
                                          />

                                          <div className="flex flex-col space-y-1">
                                            <p className="text-reg-14 text-dark !leading-[15px]">
                                              {item?.title}
                                            </p>
                                            <p className="text-reg-14 mt-[2px] !leading-[15px]">
                                              {item?.sku}
                                            </p>
                                          </div>
                                        </div>
                                      </div>
                                    </Link>
                                  ))}
                                />
                              )}
                              {data?.products && data?.products.length && (
                                <MessageMetaInfo
                                  title={"Products"}
                                  body={data?.products?.map(item => (
                                    <Link to={`/product/${item.id}`}>
                                      <div className="bg-white border border-[#EBEBEB] rounded-[8px]">
                                        <div className="flex space-x-2 p-4 items-center">
                                          <ImageCardSmall
                                            size={60}
                                            image={item?.img[0]}
                                          />
                                          <p className="text-reg-14 text-dark !leading-[15px]">
                                            {item?.title}
                                          </p>
                                        </div>
                                      </div>
                                    </Link>
                                  ))}
                                />
                              )}
                              {data?.brands && data?.brands.length && (
                                <MessageMetaInfo
                                  title={"Brands"}
                                  body={data?.brands?.map(item => (
                                    <Link to={`/brand/${item.id}`}>
                                      <div className="bg-white border border-[#EBEBEB] rounded-[8px]">
                                        <div className="flex space-x-2 p-4 items-center">
                                          <ImageCardSmall
                                            size={60}
                                            image={item?.logo}
                                          />
                                          <div className="flex flex-col space-y-1">
                                            <p className="text-reg-14 text-dark !leading-[15px]">
                                              {item?.title}
                                            </p>
                                            <p className="text-reg-14 mt-[2px] !leading-[15px]">
                                              {item?.sku}
                                            </p>
                                          </div>
                                        </div>
                                      </div>
                                    </Link>
                                  ))}
                                />
                              )}

                              {data?.store && (
                                <MessageMetaInfo
                                  title={"Location"}
                                  body={
                                    <>
                                      {data.store.name} <br />
                                      {data.store.location.address} <br />
                                    </>
                                  }
                                />
                              )}
                            </div>
                          </div>
                          <div className="text-xs text-gray-600 mt-1">
                            <ReactTimeAgo
                              date={calcTime(data.created)}
                              locale="en-US"
                            />
                          </div>
                        </div>
                      )}

                    {data?.comments?.length > 0 &&
                      data.comments.map((v, k) => {
                        if (v.uid !== currentUserData.i) {
                          console.log(v, 'vvvvvv')
                          return (
                            <div className="flex flex-row space-x-2 flex-row-reverse space-x-reverse" key={k}>

                              <CircleAvatar
                                xs
                                border
                                profile
                                image={v?.user?.avatar}
                                alt={
                                  v?.user?.name || null
                                }
                              />


                              <div className="flex flex-col">
                                <div className="bg-[#F7F7F7] rounded-[8px] p-5 text-reg-16 !leading-[1.5]">
                                  {v?.body}
                                </div>
                                <div className="text-xs text-gray-600 mt-1">
                                  <ReactTimeAgo
                                    date={calcTime(v.created)}
                                    locale="en-US"
                                  />{" "}
                                  from {v?.user.name}
                                </div>
                              </div>
                            </div>
                          )
                        }
                        return (
                          <div className="flex flex-row space-x-2 ">
                            {true ? (
                              <CircleAvatar
                                xs
                                border
                                profile
                                image={v?.user?.avatar}
                                alt={data?.to?.name || data?.from?.name || null}
                              />
                            ) : (
                              <svg
                                className="flex-none w-6 h-6"
                                fill="none"
                                stroke="currentColor"
                                viewBox="0 0 24 24"
                                xmlns="http://www.w3.org/2000/svg"
                              >
                                <path
                                  stroke-linecap="round"
                                  stroke-linejoin="round"
                                  stroke-width="2"
                                  d="M5.121 17.804A13.937 13.937 0 0112 16c2.5 0 4.847.655 6.879 1.804M15 10a3 3 0 11-6 0 3 3 0 016 0zm6 2a9 9 0 11-18 0 9 9 0 0118 0z"
                                ></path>
                              </svg>
                            )}

                            <div className="flex flex-col">
                              <div className="bg-gray-200 rounded-[8px] p-5 text-reg-16 !leading-[1.5] !text-[rgba(0,0,0,.6)]">
                                {v.body}
                              </div>
                              <div className="text-xs text-gray-600 mt-1">
                                <ReactTimeAgo
                                  date={calcTime(v.created)}
                                  locale="en-US"
                                />{" "}
                                from {v?.user.name}
                              </div>
                            </div>
                          </div>
                        )
                      })}

                    {/* <div className="flex flex-row justify-center text-xs text-gray-600 mt-1">
                            You assigned this conversation to yourself 5d ago
                          </div> */}
                  </div>

                  {data?.webform !== "Notification" && (
                    <div className="">
                      <div className="">
                        <FloatingTextarea
                          onChange={e => setBody(e.target.value)}
                          rows={2}
                          className="!mb-3"
                          value={body}
                          name="Type a message..."
                          label="Type a message..."
                        />
                      </div>
                      <div ref={fieldRef}>
                        {body ? (
                          <Button red onClick={onSend} full>
                            Send
                          </Button>
                        ) : (
                          <Button disabled full>
                            Send
                          </Button>
                        )}
                      </div>
                    </div>
                  )}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </Modal >
    // <Transition.Root appear show={open} as={Fragment}>
    //   <Dialog as="div" className="fixed inset-0 z-10" onClose={onClose}>
    //     {
    //       <Transition.Child
    //         as={Fragment}
    //         enter="ease-out duration-300"
    //         enterFrom="opacity-0"
    //         enterTo="opacity-100"
    //         leave="ease-out duration-500"
    //         leaveFrom="opacity-100"
    //         leaveTo="opacity-0"
    //       >
    //         <Dialog.Overlay className="fixed inset-0 bg-[rgba(0,0,0,.7)]" />
    //       </Transition.Child>
    //     }
    //     <div className="min-h-screen px-4 text-center">
    //       <Transition.Child
    //         as={Fragment}
    //         enter="ease-out duration-300"
    //         enterFrom="opacity-0 scale-95"
    //         enterTo="opacity-100 scale-100"
    //         leave="ease-in duration-200"
    //         leaveFrom="opacity-100 scale-100"
    //         leaveTo="opacity-0 scale-95"
    //       >
    //         <div className="pointer-events-auto relative mt-10 mx-auto w-full md:w-[600px]">
    //           <div
    //             style={{ scrollbarWidth: "none" }}
    //             className="max-h-[calc(100vh-12rem)] overflow-y-auto md:overflow-y-auto bg-white md:p-8 md:pt-6 md:top-[90px] relative border border-[#EBEBEB] rounded-[15px_15px_0_0] md:rounded-none md:border-0 md:border-l"
    //           >
    //             {isMobile === true && (
    //               <div className="flex items-center justify-between space-x-2 border-b border-[#EBEBEB] py-[10px] px-[15px]">
    //                 <h3 className="text-strong-20">
    //                   {data?.title || "Message Thread"}
    //                 </h3>
    //                 <button
    //                   type="button"
    //                   className="flex justify-center items-center bg-[#F7F7F7] rounded-[8px] h-[40px] w-[40px] ring-1 ring-offset-2 ring-transparent hover:ring-[#222] transition-all"
    //                   onClick={onClose}
    //                 >
    //                   <span className="sr-only">Close panel</span>
    //                   <CloseIcon className="h-3 w-3" aria-hidden="true" />
    //                 </button>
    //               </div>
    //             )}

    //             <div className="overflow-y-auto h-full px-[15px] md:h-auto md:px-0 md:overflow-y-visible space-y-6 pb-16">
    //               <div>
    //                 {!isMobile && (
    //                   <div
    //                     className={`flex space-x-2 ${
    //                       userType.brandManager && "mt-10"
    //                     }`}
    //                   >
    //                     <button
    //                       type="button"
    //                       className="flex justify-center items-center bg-[#F7F7F7] rounded-[8px] h-[40px] w-[40px] ring-1 ring-offset-2 ring-transparent hover:ring-[#222] transition-all"
    //                       onClick={onClose}
    //                     >
    //                       <span className="sr-only">Close panel</span>
    //                       <CloseIcon className="h-3 w-3" aria-hidden="true" />
    //                     </button>
    //                   </div>
    //                 )}

    //                 <div className="flex flex-row  h-full mb-6">
    //                   <div className="flex flex-row flex-auto">
    //                     <div className="w-full flex flex-col">
    //                       <p className="hidden md:block py-4 text-med-18">
    //                         {data?.title || "Message Thread"}
    //                       </p>
    //                       <div className="pt-5 md:pt-0 flex items-center border-b pb-5 w-full">
    //                         {data?.webform === "Notification" ? (
    //                           <CircleAvatar
    //                             sm
    //                             border
    //                             image={Logo}
    //                             padding="6px"
    //                             alt={"O2O"}
    //                           />
    //                         ) : (
    //                           <CircleAvatar
    //                             sm
    //                             border
    //                             image={data?.brand?.logo}
    //                             alt={
    //                               brandView === true
    //                                 ? data?.store?.name || ""
    //                                 : data?.brand?.name || ""
    //                             }
    //                           />
    //                         )}
    //                         <div className="flex flex-col items-start ml-[13px]">
    //                           <span
    //                             className="text-meta-sm !text-left"
    //                             css={css`
    //                               &&& {
    //                                 font-size: 14px;
    //                                 letter-spacing: 0.02em;
    //                               }
    //                             `}
    //                           >
    //                             {brandView === true && (
    //                               <>
    //                                 {data?.store?.name}
    //                                 {data?.store?.retailer?.name &&
    //                                   `, ${data?.store?.retailer?.name}`}
    //                               </>
    //                             )}
    //                             {brandView === false && data?.brand?.name}
    //                           </span>

    //                           <span className="flex items-center text-reg-12">
    //                             <span className="w-[20px]">
    //                               {data &&
    //                               "webform" in data &&
    //                               data?.webform.includes("visit") ? (
    //                                 <ShopIcon className="w-3 w-3" />
    //                               ) : (
    //                                 <ChatAlt2Icon
    //                                   className="w-4 w-4"
    //                                   css={css`
    //                                     stroke-width: 1px;
    //                                     stroke: #222;
    //                                   `}
    //                                 />
    //                               )}
    //                             </span>
    //                             {data?.webform}
    //                           </span>
    //                         </div>
    //                       </div>
    //                       {brandView === true &&
    //                         data?.store?.location?.address && (
    //                           <div className="pt-5 flex items-center border-b pb-5 w-full">
    //                             <span className="text-reg-12">
    //                               {data.store.location.address}
    //                             </span>
    //                           </div>
    //                         )}

    //                       <div className="flex-auto overflow-y-auto  py-6 space-y-4">
    //                         {data?.body &&
    //                           !data?.webform?.includes("asset") &&
    //                           !data?.webform?.includes("Visit") && (
    //                             <div className="flex flex-row space-x-2">
    //                               <svg
    //                                 className="flex-none w-6 h-6"
    //                                 fill="none"
    //                                 stroke="currentColor"
    //                                 viewBox="0 0 24 24"
    //                                 xmlns="http://www.w3.org/2000/svg"
    //                               >
    //                                 <path
    //                                   stroke-linecap="round"
    //                                   stroke-linejoin="round"
    //                                   stroke-width="2"
    //                                   d="M5.121 17.804A13.937 13.937 0 0112 16c2.5 0 4.847.655 6.879 1.804M15 10a3 3 0 11-6 0 3 3 0 016 0zm6 2a9 9 0 11-18 0 9 9 0 0118 0z"
    //                                 ></path>
    //                               </svg>
    //                               <div className="flex flex-col">
    //                                 <div className="bg-[#F7F7F7] rounded-[8px] p-5 text-reg-16 !leading-[1.5]">
    //                                   {data.body}
    //                                 </div>
    //                                 <div className="text-xs text-gray-600 mt-1">
    //                                   <ReactTimeAgo
    //                                     date={calcTime(data.created)}
    //                                     locale="en-US"
    //                                   />
    //                                 </div>
    //                               </div>
    //                             </div>
    //                           )}
    //                         {data &&
    //                           "webform" in data &&
    //                           data?.webform.includes("Asset") && (
    //                             <div className="flex flex-col w-full">
    //                               <div className="bg-[#F7F7F7] rounded-[8px] p-3 text-reg-16 !leading-[1.5]">
    //                                 <div className="bg-white border border-[#EBEBEB] rounded-[8px]">
    //                                   <div className="flex space-x-2 p-4 items-center">
    //                                     <ImageCardSmall
    //                                       size={60}
    //                                       image={data.asset.cover[0]}
    //                                     />
    //                                     <div className="flex flex-col space-y-1">
    //                                       {/* <p className="text-med-10 !leading-[12px]">{data.brand.name}</p> */}
    //                                       <p className="text-reg-14 text-dark !leading-[15px]">
    //                                         {data.asset.title}
    //                                       </p>
    //                                       <p className="text-reg-14 mt-[2px] !leading-[15px]">
    //                                         {data.asset.sku}
    //                                       </p>
    //                                     </div>
    //                                   </div>
    //                                   <div className="px-4">
    //                                     <Table
    //                                       sm
    //                                       columns={["Address", "Qty"]}
    //                                       flush
    //                                       flushHead
    //                                     >
    //                                       {"delivery_locations" in data &&
    //                                         data.delivery_locations.map(
    //                                           (item, k) => {
    //                                             return (
    //                                               <Tr key={k}>
    //                                                 <Td>
    //                                                   <span className="whitespace-pre-wrap text-reg-14 !leading-[1.5]">
    //                                                     {item.location}
    //                                                   </span>
    //                                                 </Td>
    //                                                 <Td>
    //                                                   <span className="text-reg-14 !leading-[1.5]">
    //                                                     {data?.quantities[k]}
    //                                                   </span>
    //                                                 </Td>
    //                                               </Tr>
    //                                             )
    //                                           }
    //                                         )}
    //                                     </Table>
    //                                   </div>
    //                                 </div>
    //                                 <div className="px-4 py-4">
    //                                   <MessageMetaInfo
    //                                     title={"Requester"}
    //                                     body={
    //                                       <>
    //                                         {data.requester.name && (
    //                                           <>
    //                                             {data.requester.name} <br />
    //                                           </>
    //                                         )}
    //                                         {data.requester.job_title && (
    //                                           <>
    //                                             {data.requester.job_title}{" "}
    //                                             <br />
    //                                           </>
    //                                         )}
    //                                         {data.requester.companies.join(
    //                                           ", "
    //                                         )}
    //                                       </>
    //                                     }
    //                                   />
    //                                   {data?.products && (
    //                                     <MessageMetaInfo
    //                                       title={"Products carried"}
    //                                       body={data.products
    //                                         .map(i => i.title)
    //                                         .join(", ")}
    //                                     />
    //                                   )}
    //                                   {data?.message && (
    //                                     <MessageMetaInfo
    //                                       title={"Message"}
    //                                       body={data.message}
    //                                     />
    //                                   )}

    //                                   {/* <div className="flex align-center space-x-2 mt-4">
    //                                   <Button red widthAuto onClick={() => requestAssetAction(true)}>Approve</Button>
    //                                   <Button black outline widthAuto onClick={() => requestAssetAction(false)}>Deny</Button>
    //                                 </div> */}

    //                                   {assetRequestStatus === "pending" &&
    //                                     (userType.brandEditor === true ||
    //                                       userType.brandManager === true) && (
    //                                       <div className="flex align-center space-x-2 mt-4">
    //                                         <Button
    //                                           red
    //                                           widthAuto
    //                                           onClick={() =>
    //                                             requestAssetAction(true)
    //                                           }
    //                                         >
    //                                           Approve
    //                                         </Button>
    //                                         <Button
    //                                           black
    //                                           outline
    //                                           widthAuto
    //                                           onClick={() =>
    //                                             requestAssetAction(false)
    //                                           }
    //                                         >
    //                                           Deny
    //                                         </Button>
    //                                       </div>
    //                                     )}
    //                                   {assetRequestStatus !== "pending" && (
    //                                     <div>
    //                                       {userType.brandEditor === true ||
    //                                         (userType.brandManager === true && (
    //                                           <>
    //                                             {assetRequestStatus ===
    //                                             "approved"
    //                                               ? "You've approved this request."
    //                                               : "You've denied this request."}
    //                                           </>
    //                                         ))}
    //                                       {userType.retailerEditor === true ||
    //                                         (userType.retailerManager ===
    //                                           true && (
    //                                           <>
    //                                             {assetRequestStatus ===
    //                                             "approved"
    //                                               ? "This request has been approved."
    //                                               : "This request has been denied."}
    //                                           </>
    //                                         ))}
    //                                     </div>
    //                                   )}
    //                                 </div>
    //                               </div>
    //                               <div className="text-xs text-gray-600 mt-1">
    //                                 <ReactTimeAgo
    //                                   date={calcTime(data.created)}
    //                                   locale="en-US"
    //                                 />
    //                               </div>
    //                             </div>
    //                           )}

    //                         {data &&
    //                           "webform" in data &&
    //                           data?.webform.includes("Visit") && (
    //                             <div className="flex flex-col w-full">
    //                               <div className="bg-[#F7F7F7] rounded-[8px] p-3 text-reg-16 !leading-[1.5]">
    //                                 <div className="px-4 py-4">
    //                                   {/* <MessageMetaInfo
    //                                     title={"Requester"}
    //                                     body={
    //                                       <>

    //                                       </>
    //                                     }
    //                                   /> */}
    //                                   {data?.body && data?.body !== "" && (
    //                                     <MessageMetaInfo
    //                                       title={"Message"}
    //                                       body={data.body}
    //                                     />
    //                                   )}
    //                                   {data?.purpose &&
    //                                     data?.purpose !== "" && (
    //                                       <MessageMetaInfo
    //                                         title={"Purpose"}
    //                                         body={data.purpose}
    //                                       />
    //                                     )}
    //                                   {data?.days && data?.days !== "" && (
    //                                     <MessageMetaInfo
    //                                       title={"Days"}
    //                                       body={data.days}
    //                                     />
    //                                   )}
    //                                   {data?.time_of_day &&
    //                                     data?.time_of_day !== "" && (
    //                                       <MessageMetaInfo
    //                                         title={"Time of Day"}
    //                                         body={data.time_of_day}
    //                                       />
    //                                     )}
    //                                   {data?.store && (
    //                                     <MessageMetaInfo
    //                                       title={"Location"}
    //                                       body={
    //                                         <>
    //                                           {data.store.name} <br />
    //                                           {data.store.location.address}{" "}
    //                                           <br />
    //                                         </>
    //                                       }
    //                                     />
    //                                   )}
    //                                 </div>
    //                               </div>
    //                               <div className="text-xs text-gray-600 mt-1">
    //                                 <ReactTimeAgo
    //                                   date={calcTime(data.created)}
    //                                   locale="en-US"
    //                                 />
    //                               </div>
    //                             </div>
    //                           )}
    //                         {data?.comments?.length > 0 &&
    //                           data.comments.map((v, k) => {
    //                             if (v.uid === "0")
    //                               return (
    //                                 <div
    //                                   className="flex flex-row space-x-2"
    //                                   key={k}
    //                                 >
    //                                   <svg
    //                                     className="flex-none w-6 h-6"
    //                                     fill="none"
    //                                     stroke="currentColor"
    //                                     viewBox="0 0 24 24"
    //                                     xmlns="http://www.w3.org/2000/svg"
    //                                   >
    //                                     <path
    //                                       stroke-linecap="round"
    //                                       stroke-linejoin="round"
    //                                       stroke-width="2"
    //                                       d="M5.121 17.804A13.937 13.937 0 0112 16c2.5 0 4.847.655 6.879 1.804M15 10a3 3 0 11-6 0 3 3 0 016 0zm6 2a9 9 0 11-18 0 9 9 0 0118 0z"
    //                                     ></path>
    //                                   </svg>

    //                                   <div className="flex flex-col">
    //                                     <div className="bg-[#F7F7F7] rounded-[8px] p-5 text-reg-16 !leading-[1.5]">
    //                                       {v?.body}
    //                                     </div>
    //                                     <div className="text-xs text-gray-600 mt-1">
    //                                       <ReactTimeAgo
    //                                         date={calcTime(v.created)}
    //                                         locale="en-US"
    //                                       />
    //                                     </div>
    //                                   </div>
    //                                 </div>
    //                               )
    //                             return (
    //                               <div className="flex flex-row space-x-2 flex-row-reverse space-x-reverse">
    //                                 <svg
    //                                   className="flex-none w-6 h-6"
    //                                   fill="none"
    //                                   stroke="currentColor"
    //                                   viewBox="0 0 24 24"
    //                                   xmlns="http://www.w3.org/2000/svg"
    //                                 >
    //                                   <path
    //                                     stroke-linecap="round"
    //                                     stroke-linejoin="round"
    //                                     stroke-width="2"
    //                                     d="M5.121 17.804A13.937 13.937 0 0112 16c2.5 0 4.847.655 6.879 1.804M15 10a3 3 0 11-6 0 3 3 0 016 0zm6 2a9 9 0 11-18 0 9 9 0 0118 0z"
    //                                   ></path>
    //                                 </svg>

    //                                 <div className="flex flex-col">
    //                                   <div className="bg-gray-200 rounded-[8px] p-5 text-reg-16 !leading-[1.5] !text-[rgba(0,0,0,.6)]">
    //                                     {v.body}
    //                                   </div>
    //                                   <div className="text-xs text-gray-600 mt-1">
    //                                     <ReactTimeAgo
    //                                       date={calcTime(v.created)}
    //                                       locale="en-US"
    //                                     />
    //                                   </div>
    //                                 </div>
    //                               </div>
    //                             )
    //                           })}

    //                         {/* <div className="flex flex-row justify-center text-xs text-gray-600 mt-1">
    //                             You assigned this conversation to yourself 5d ago
    //                           </div> */}
    //                       </div>

    //                       {data?.webform !== "Notification" && (
    //                         <div className="">
    //                           <div className="">
    //                             <FloatingTextarea
    //                               onChange={e => setBody(e.target.value)}
    //                               rows={2}
    //                               className="!mb-3"
    //                               value={body}
    //                               name="Type a message..."
    //                               label="Type a message..."
    //                             />
    //                           </div>
    //                           <div ref={fieldRef}>
    //                             {body ? (
    //                               <Button red onClick={onSend} full>
    //                                 Send
    //                               </Button>
    //                             ) : (
    //                               <Button disabled full>
    //                                 Send
    //                               </Button>
    //                             )}
    //                           </div>
    //                         </div>
    //                       )}
    //                     </div>
    //                   </div>
    //                 </div>
    //               </div>
    //             </div>
    //           </div>
    //         </div>
    //       </Transition.Child>
    //     </div>
    //   </Dialog>
    // </Transition.Root>
  )
}

function MessageSingle(props) {
  const { id, open, onClose } = props
  const { token } = useContext(StateContext).state
  const { toggleNotification } = useContext(NotificationContext)

  // if (!id) {
  //   return <Redirect to="/dashboard/messages/" />
  // } else {
  return <MessagesList token={token} {...props} />
  // }
}

export default MessageSingle
