/** @jsx jsx */
import { css, jsx } from "@emotion/react"
import React, { useContext, useEffect, useState } from "react"
import LayoutDashborad from "../../components/Layout/Dashboard"
import {
  archiveMessage,
  // getMyListing,
  getMyMessageReceived,
  getMyMessageSent,
  getMyActiveMessages,
  getMyArchiveMessages,
  getMessageCompanies,
} from "../../helpers/DrupalHelper"
import StateContext from "../../context/state/StateContext"
import Modal from "../../components/Modal/Modal"
import EditFormTemplate from "../../components/Forms/EditFormTemplate"
import CircleAvatar from "../../components/Elements/CircleAvatar"
import ShopIcon from "../../assets/icons/shop.svg"
import NotificationContext from "../../context/Notification/NotificationContext"
import {
  CheckCircleIcon,
  ChevronRightIcon,
  MailIcon,
  InboxInIcon,
  ChatAlt2Icon,
  BellIcon,
} from "@heroicons/react/outline"
import { Table, Tr, Td } from "../../components/Table/TableElements"
import { Tab } from "@headlessui/react"
import AuthenticationContext from "../../context/authentication/AuthenticationContext"
import { classNames } from "../../utils"
// import { Link } from "@reach/router"
import MessageSingle from "../../templates/MessageSingle"
import Skeleton from "../../components/Skeleton"
import DataContext from "../../context/Data/DataContext"
import Logo from "../../assets/logos/o2o-logo-r.png"
import PageSubtitle from "../../components/PageSubtitle"
import Button from "../../components/Elements/Button"
import CreateMessage from "../../components/Forms/DynamicForm/CreateMessage"
import { unixToDate } from "../../helpers/UnixToDate"
import FloatingInput from "../../components/Elements/Form/FloatingInput"
import FloatingSelect from "../../components/Elements/Form/FloatingSelect"
import FloatingDateRange from "../../components/Elements/Form/FloatingDateRange"
import { useForm } from "react-hook-form"
import ButtonFilter from "../../components/Elements/ButtonFilter"

function MessageTable({
  props,
  item,
  k,
  openMessage,
  current,
  setArchiveTrigger,
  state,
}) {
  const { toggleNotification } = useContext(NotificationContext)
  const user_id = Number(
    useContext(AuthenticationContext).authentication.currentUserData.i
  )
  const [archiveLoading, setArchiveLoading] = useState(false)
  const handleArchiveClick = async item => {
    setArchiveLoading(true)

    const body = { id: item.id, type: item.type }
    try {
      const res = await archiveMessage(state.token, body)

      if (res.success === "200") {
        toggleNotification({
          content:
            item.status === "active"
              ? "Successfully archived the message"
              : "Successfully restored the message",
        })
        setArchiveTrigger(true)
      }
    } catch (e) {
      toggleNotification({
        error: true,
        content:
          item.status === "active"
            ? "There was an error archiving the message"
            : "There was an error restoring the message",
      })
    }
    setArchiveLoading(false)
  }
  return (
    <Tr key={k}>
      <div
        onClick={() => openMessage(item.id)}
        // to={`/dashboard/messages/t/${item.webform_submission_id}`}
        className={`block hover:bg-[#f7f7f7] cursor-pointer ${
          item.id === current && `bg-[#f7f7f7]`
        }`}
        // state={{ id: item.webform_submission_id }}
      >
        <div className="flex items-center px-4 py-4 sm:px-6">
          <div className="min-w-0 flex-1 flex items-center">
            <div className="flex-shrink-0">
              {item.type === "notification" ? (
                <CircleAvatar
                  padding={"6px"}
                  sm
                  border
                  image={Logo}
                  alt={"O2O"}
                />
              ) : (
                <CircleAvatar
                  sm
                  border
                  profile
                  image={
                    item.type !== "general_message"
                      ? item?.brand?.logo
                      : user_id === item?.from?.id
                      ? item?.to?.avatar
                      : item?.from?.avatar
                  }
                  alt={item?.to?.name || item?.from?.name || null}
                  />
              )}
            </div>
            <div className="min-w-0 flex-1 px-4 md:grid md:grid-cols-5 md:gap-4 flex items-center">
              <div className="flex flex-col justify-center">
                {item.type === "general_message" ? (
                  Number(user_id) === item?.to?.id ? (
                    <p className="text-med-15">{item?.from?.name}</p>
                  ) : (
                    <p className="text-med-15">
                      {item?.to?.name || item?.from?.name}
                    </p>
                  )
                ) : (
                  <p className="text-med-15">
                    {item?.brand?.title || item.title}
                  </p>
                )}
                <p className="flex items-center text-reg-12">
                  <div className="w-[20px]">
                    {item.type === "request_visit" && (
                      <ShopIcon className="w-3 w-3" />
                    )}
                    {item.type === "notification" && (
                      <BellIcon
                        className="w-4 w-4"
                        css={css`
                          stroke-width: 1px;
                          stroke: #222;
                        `}
                      />
                    )}
                    {item.type === "request_asset" && (
                      <ChatAlt2Icon
                        className="w-4 w-4"
                        css={css`
                          stroke-width: 1px;
                          stroke: #222;
                        `}
                      />
                    )}
                    {item.type === "general_message" && (
                      <ChatAlt2Icon
                        className="w-4 w-4"
                        css={css`
                          stroke-width: 1px;
                          stroke: #222;
                        `}
                      />
                    )}
                    {item.type === "contact_us" && (
                      <ChatAlt2Icon
                        className="w-4 w-4"
                        css={css`
                          stroke-width: 1px;
                          stroke: #222;
                        `}
                      />
                    )}
                  </div>
                  <span className="capitalize">
                    {item?.type?.replaceAll("_", " ")}
                  </span>
                </p>
              </div>

              <div className="hidden md:block">
                <p className="capitalize text-reg-15">
                  {item?.title?.replaceAll("_", " ")}
                </p>
              </div>

              <div className="hidden md:block">
                <p className="text-reg-15">{`Created ${unixToDate(
                  item.created
                )}`}</p>
              </div>
              <div className="hidden md:block">
                <p className="text-reg-15">
                  {/* Applied on{" "} */}
                  <time
                    dateTime={
                      item.last_comment_date
                        ? item.last_comment_date
                        : item.created
                    }
                  >
                    {item.last_comment_date
                      ? "Last reply " + item.last_comment_date
                      : ""}
                  </time>
                </p>
              </div>
              <div className="ml-5">
                <Button
                  bordered
                  onClick={event => {
                    event.stopPropagation()
                    handleArchiveClick(item)
                  }}
                  loading={archiveLoading}
                >
                  {item.status === "active" ? "Archive" : "Restore"}
                </Button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </Tr>
  )
}

function MessagesList(props) {
  const {
    list,
    //  isSent,
    open,
    current,
    openMessage,
    location,
    state,
    setMessageFilter,
    setArchiveTrigger,
  } = props
  // console.log({ list })
  const {
    register,
    handleSubmit,
    setValue,
    formState: { errors },
    watch,
    getValues,
    unregister,
    control,
    formState,
    reset,
  } = useForm()

  // let endDate = new Date()
  // let startDate = new Date()
  // startDate.setMonth(startDate.getMonth() - 3)
  const [resetState, setResetState] = useState(false)
  const [dateRange, setDateRange] = useState()
  const [messageCompanies, setMessageCompanies] = useState()
  const callReset = () => {
    setResetState(true)
    reset()
    setMessageFilter({})
    setDateRange()
  }

  useEffect(async () => {
    const res = await getMessageCompanies(state.token)
    const resAsOpt = res?.map(item => {
      return { value: item.sid, label: item.company_name }
    })
    setMessageCompanies(resAsOpt)
  }, [])

  useEffect(() => {
    const v = getValues()
    let company_field = []
    if (v.companies) {
      v.companies.forEach(item => {
        const numbers = item.split(",")
        numbers.forEach(number => {
          company_field.push(parseInt(number))
        })
      })
    }
    const start_date =
      dateRange?.length && dateRange[0].startDate.toLocaleDateString("en-GB")
    const end_date =
      dateRange?.length && dateRange[0].endDate.toLocaleDateString("en-GB")
    const search = v.search === "" ? undefined : v.search
    const company = company_field?.length ? company_field : undefined
    const type = v.type?.length ? v.type : undefined
    const body = { search, company, type, start_date, end_date }
    if (resetState) {
      setResetState(false)
      return
    }
    setMessageFilter(body)
  }, [watch("search"), watch("type"), watch("companies"), dateRange])

  return (
    <>
      <form className="mb-6">
        <div className="grid grid-cols-1 gap-4  sm:grid-cols-4">
          <FloatingInput
            label="Search"
            name="search"
            hookForm
            register={register}
            control={control}
          />
          <FloatingSelect
            options={[
              { value: "general_message", label: "General Message" },
              { value: "request_asset", label: "Request Asset" },
              { value: "request_visit", label: "Request Visit" },
              { value: "contact_us", label: "Contact Us" },
            ]}
            hookForm
            isMulti
            register={register}
            label={"Filter by Message Type"}
            name="type"
            control={control}
          />
          <FloatingSelect
            options={messageCompanies}
            label={"Filter by Company"}
            hookForm
            isMulti
            register={register}
            name="companies"
            control={control}
          />

          <FloatingDateRange
            name="range"
            label="Filter by Date Range"
            dateRange={dateRange}
            setDateRange={setDateRange}
          />
        </div>

        <ButtonFilter
          color={"grey"}
          type="button"
          callback={callReset}
          title="Reset Filters"
          iconColor="black"
          reset
          noMargin
        />
      </form>
      <Table>
        {list.length ? (
          list.map((item, k) => (
            <MessageTable
              k={k}
              item={item}
              openMessage={openMessage}
              current={current}
              setArchiveTrigger={setArchiveTrigger}
              state={state}
            />
          ))
        ) : (
          <div className="p-4">There are no messages to display.</div>
        )}
      </Table>
    </>
  )
}

const Tabs = props => {
  const {
    sentMessages,
    messageList,
    archiveMessageList,
    receivedMessages,
    open,
    current,
    openMessage,
    state,
  } = props
  const { currentUserData } = useContext(AuthenticationContext).authentication

  const [currentTab, setCurrentTab] = useState("Company")
  // const tabs = [
  //   { name: "Sent", href: "#", component: false },
  //   { name: "Received", href: "#", component: false },
  // ]
  const tabs = [
    { name: "Active", href: "#", component: false },
    { name: "Archived", href: "#", component: false },
  ]

  return (
    <div>
      <Tab.Group defaultIndex={0}>
        <Tab.List className="-mb-px flex space-x-8 border-b border-[#EBEBEB]">
          {tabs.map((item, k) => (
            <Tab
              key={k}
              className={({ selected }) =>
                classNames(
                  selected
                    ? "border-[#222] text-[#222]"
                    : "border-transparent text-[#222] hover:text-gray-500",
                  "whitespace-nowrap pb-[28px] px-1 border-b-2 text-med-16"
                )
              }
            >
              {item.name}
            </Tab>
          ))}
        </Tab.List>
        <Tab.Panels className="pt-[30px]">
          <Tab.Panel key={0}>
            <MessagesList list={messageList} {...props} />
          </Tab.Panel>
          <Tab.Panel key={1}>
            <MessagesList list={archiveMessageList} {...props} />
          </Tab.Panel>
        </Tab.Panels>
      </Tab.Group>
    </div>
  )
}

const MessagesLists = props => <Tabs {...props} />
function Messages({ location }) {
  // console.log(location)
  const { state } = useContext(StateContext)
  const { setData } = useContext(DataContext)
  const [sentMessages, setSentMessages] = useState([])
  const [receivedMessages, setReceivedMessages] = useState([])
  const [list, setList] = useState([])
  // const [open, setOpen] = useState(false)
  const [current, setCurrent] = useState(null)
  const [openAfterSave, setOpenAfterSave] = useState(false)
  const [contentAfterSave, setContentAfterSave] = useState("")
  const [editCurrent, setEditCurrent] = useState(null)
  const [formData, setFormData] = useState(null)
  const { toggleNotification } = useContext(NotificationContext)
  const { messages } = useContext(DataContext).data
  const [loading, setLoading] = useState(true)

  const [messageList, setMessageList] = useState([])
  const [archiveMessageList, setArchiveMessageList] = useState([])
  const { userType } = useContext(AuthenticationContext)
  const [messageFilter, setMessageFilter] = useState({})
  const [archiveTrigger, setArchiveTrigger] = useState(false)

  useEffect(async () => {
    try {
      const x = await getMyActiveMessages(state.token, messageFilter)
      let temp = x.map(item => {
        return { ...item, status: "active" }
      })
      // console.log({ temp })
      setMessageList(temp)
      setData(prevState => ({
        ...prevState,
        messages: { ...prevState.messages, sentMessages: temp },
      }))
    } catch (e) {
      toggleNotification({
        error: true,
        content:
          "There was an error retrieving your inbox. Please refresh the page.",
      })
    }
    try {
      const x = await getMyArchiveMessages(state.token, messageFilter)
      let temp = x.map(item => {
        return { ...item, status: "archive" }
      })
      setArchiveMessageList(temp)
    } catch (e) {
      toggleNotification({
        error: true,
        content:
          "There was an error retrieving your inbox. Please refresh the page.",
      })
    }

    setLoading(false)
    setArchiveTrigger(false)
  }, [messageFilter, archiveTrigger])
  // console.log({ messageList })
  useEffect(async () => {
    // console.log(state)

    // if (messages == null || messages == undefined) {
    //   let sentMessage = await getMyMessageSent(state.token)
    //   setSentMessages(sentMessage)
    //   let ReceivedMessage = await getMyMessageReceived(state.token)
    //   setReceivedMessages(ReceivedMessage)
    //   setLoading(false)
    // } else {
    //   setSentMessages(messages.sentMessages)
    //   setReceivedMessages(messages.receivedMessage)

    //   setLoading(false)
    // }
    if (location?.state?.id) {
      let id = location?.state.id
      openMessage(id)
    }
  }, [])
  useEffect(() => {
    if (location?.state?.id) {
      let id = location?.state.id
      openMessage(id)
    }
  }, [location?.state?.id])

  // useEffect(() => {
  //   if (current !== null) setOpen(true)
  //   if(!current) setOpen(false)
  // }, [current, open])

  const openMessage = id => {
    setCurrent(id)
  }
  const [modalOpen, setModalOpen] = useState(false)

  // const closeMessage = () => {
  //   setCurrent(null)
  // }

  return (
    <LayoutDashborad metaTitle="My Messages">
      <div
        className={`mb-8 pb-6 ${
          userType.brandEditor
            ? "lg:mt-[130px] md:mt-[130px]"
            : !userType.brandManager && "lg:mt-20 md:mt-20"
        }`}
      >
        <PageSubtitle title="Send, receive, reply and organize your messages and requests." />
        <div className="container ">
          <div className={`w-[100%]`}>
            <Modal
              isOpen={modalOpen}
              title="Create New Message"
              closeModal={() => setModalOpen(false)}
            >
              <CreateMessage
                setModalOpen={setModalOpen}
                setArchiveTrigger={setArchiveTrigger}
              />
            </Modal>
            <div className="mb-5 mt-5">
              <Button
                red
                widthAuto
                onClick={() => {
                  setModalOpen(true)
                }}
              >
                Create New Message
              </Button>
            </div>
            {!loading ? (
              <MessagesLists
                sentMessages={sentMessages}
                receivedMessages={receivedMessages}
                open={current !== null}
                current={current}
                openMessage={openMessage}
                messageList={messageList}
                archiveMessageList={archiveMessageList}
                state={state}
                setMessageFilter={setMessageFilter}
                setArchiveTrigger={setArchiveTrigger}
              />
            ) : (
              <Skeleton table />
            )}
          </div>
          {/*current !== null && (*/}
          {current !== null && (
            <MessageSingle
              open={current !== null}
              id={current}
              onClose={() => setCurrent(null)}
              sentMessages={sentMessages}
              receivedMessages={receivedMessages}
            />
          )}
          {/*})}*/}
        </div>
      </div>
    </LayoutDashborad>
  )
}

export default Messages
